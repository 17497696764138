import { makeStyles, Theme } from '@material-ui/core';

export const useCompanyStyle = makeStyles<Theme, { currentTheme: string }>(
  theme => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      gap: 16,
      alignItems: 'baseline',
      height: '100vh',
      // paddingTop: 100,
      // paddingBottom: 24,
    },
    borderStyle: {
      borderColor: ({ currentTheme }) =>
        currentTheme === 'dark' ? '#666666' : '#D0D0D0',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
      border: '1px solid',
    },
    floatActionSection: {
      padding: 16,
      background: theme.palette.background.paper,
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flexStart',
      marginTop: 32,
    },
    paper: {
      overflowY: 'scroll',
      background: theme.palette.background.paper,
      borderRadius: 0,
      padding: '30px 26px 26px',
      flexGrow: 1,
      height: '100vh',
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    title: {
      marginBottom: '20px',
      marginTop: 40,
    },
    gridButton: {
      display: 'flex',
      // alignItems: 'center',
      justifyContent: 'center',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1000,
      color: '#fff',
    },
    dateExpireGrid: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& label': {
        fontSize: 14,
      },
    },
    dateExpireButton: {
      position: 'absolute',
      right: 5,
    },
    fieldSection: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: 16,
    },
    fieldRow: {
      display: 'flex',
      alignItems: 'center',
      gap: 18,
      '& > *': {
        flexGrow: 1,
      },
    },
  }),
);
