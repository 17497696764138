/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  Grid,
  Button,
  TableHead,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import { getComparator, stableSort } from 'utils/tableSort';

const useStyles = makeStyles(theme => (
   {
    root: {
      width: '100%',
      height: '100%',
      // maxHeight: 400,
    },
    paper: {
      width: '100%',
      maxHeight: ({ maxHeightTable }) => maxHeightTable || 450,
      minHeight: ({ minHeightTable }) => minHeightTable || undefined,
      // minWidth: ({ minWidthTable }) => minWidthTable || undefined,
      // maxWidth: ({ maxWidthTable }) => maxWidthTable || undefined,
    },
    table: {
      [theme.breakpoints.up('sm')]: {
        height: 370
      },
      [theme.breakpoints.down('md')]: {
        height: 250
      }
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    title: {
      flex: '1 1 100%',
    },
    thead: {
      // background: theme.palette.titleBar.main,
    },
    th: {
      fontSize: 10,
      textAlign: 'center',
      // background: theme.palette.titleBar.main,
    },
    td: {
      fontSize: 10,
      textAlign: 'center',
    },
    checkbox: {},
  }
));

function EnhancedTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
}) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.length > 0 ? (
          <>
            <TableCell padding="checkbox" className={classes.th}>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all devices' }}
              />
            </TableCell>
            {headCells.map(headCell => (
              <Tooltip key={headCell.id} title={headCell.label} placement="top">
                <TableCell
                  align={headCell.align}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.value ? order : false}
                  className={classes.th}
                  style={{ minWidth: headCell.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.value}
                    direction={orderBy === headCell.value ? order : 'asc'}
                    onClick={createSortHandler(headCell.value)}
                  >
                    {headCell.icon ? headCell.icon : headCell.label}
                    {orderBy === headCell.value ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              </Tooltip>
            ))}
          </>
        ) : null}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ numSelected, children, classes }) {
  return (
    <Toolbar>
      <Grid className={classes.buttonContainer}>{children}</Grid>
      <Grid
        className={clsx(classes.toolbar, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 && (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selecionado(s)
          </Typography>
        )}
      </Grid>
    </Toolbar>
  );
}

export function EnhancedTable({
  toolbar = false,
  headCells = [],
  dataCells = [],
  tableActions = [],
  loading = false,
  // eslint-disable-next-line prettier/prettier
  // eslint-disable-next-line no-unused-vars
  onRowClick = id => { },
  selectedRow = [],
  // eslint-disable-next-line prettier/prettier
  // eslint-disable-next-line no-unused-vars
  selectAllRows = row => { },
  maxHeightTable,
  minHeightTable,
  maxWidthTable,
  minWidthTable,
  defaultRowsPerPage = 5,
  rowsPerPageOptions = [5, 10, 25, 50, 100],
  hasPagination = true,
}) {
  const classes = useStyles({
    maxHeightTable,
    minHeightTable,
    maxWidthTable,
    minWidthTable,
  });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const renderButtons = useMemo(() => {
    if (tableActions.length > 1) {
      return (
        <section style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
          {tableActions.map(({ id, icon: Icon, action, title }) => {
            return (
              <Button
                key={id}
                type="button"
                onClick={() => action(selectedRow)}
                variant="contained"
                color="primary"
                size="medium"
              >
                <Icon style={{ fontSize: 15, paddingBottom: 2 }} /> {title}
              </Button>
            );
          })}
        </section>
      );
    }
    return tableActions.map(({ id, icon: Icon, action, title }) => (
      <Button
        key={id}
        type="button"
        variant="contained"
        size="small"
        onClick={() => action(selectedRow)}
      >
        <Icon style={{ fontSize: 15, paddingBottom: 2 }} /> {title}
      </Button>
    ));
  }, [selectedRow, tableActions]);

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order],
  );
  const handleSelectAllClick = useCallback(
    e => {
      const { checked } = e.target;

      if (checked) {
        const newSelecteds = dataCells?.map(item => item.id);

        selectAllRows(newSelecteds);
        return;
      }
      selectAllRows([]);
    },
    [selectAllRows, dataCells],
  );

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TableBodyContent = useCallback(() => {
    const isSelected = name => selectedRow.indexOf(name) !== -1;

    if (!toolbar) {
      return dataCells.length > 0 ? (
        stableSort(dataCells, getComparator(order, orderBy)).map(
          (item, index) => {
            const isItemSelected = isSelected(item.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const data = [...headCells.map(head => item[head.value])];

            return (
              <TableRow
                hover
                onClick={() => {
                  onRowClick(item.id)
                }}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={item.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>

                {data.map((i, k) => (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={k}
                    id={labelId}
                    className={classes.td}
                    align={i?.align || 'center'}
                  >
                    {i}
                  </TableCell>
                ))}
              </TableRow>
            );
          },
        )
      ) : (
        <TableRow>
          <TableCell
            colSpan={headCells.length + 1}
            style={{ textAlign: 'center' }}
          >
            Vazio
          </TableCell>
        </TableRow>
      );
    }
    return dataCells.length > 0 ? (
      stableSort(dataCells, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          const data = [...headCells.map(head => item[head.value])];


          return (
            <TableRow
              hover
              onClick={() => {
                onRowClick(item.id)
              }}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={item.id}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>

              {data.map((i, k) => (
                <TableCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={k}
                  id={labelId}
                  className={classes.td}
                >
                  {i}
                </TableCell>
              ))}
            </TableRow>
          );
        })
    ) : (
      <TableRow>
        <TableCell
          colSpan={headCells.length + 1}
          style={{ textAlign: 'center' }}
        >
          Vazio
        </TableCell>
      </TableRow>
    );
  }, [
    classes.td,
    order,
    orderBy,
    page,
    rowsPerPage,
    onRowClick,
    dataCells,
    headCells,
    selectedRow,
    toolbar,
  ]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={classes.table}>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedRow.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={hasPagination ? rowsPerPage : dataCells.length}
              headCells={headCells}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Skeleton variant="rect" animation="pulse" width="auto" />
                  </TableCell>
                </TableRow>
              ) : (
                <TableBodyContent />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {toolbar && (
          <>
            <TablePagination
              labelRowsPerPage="Qtd por pag."
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={dataCells.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <EnhancedTableToolbar
              numSelected={selectedRow.length}
              classes={classes}
            >
              {renderButtons}
            </EnhancedTableToolbar>
          </>
        )}
      </Paper>
    </div>
  );
}
