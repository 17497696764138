import { useQuery } from 'react-query';
import Api from 'services/api';

interface IRequest {
  companyId: number;
}

interface IOutput {
  id: number;
  imei: string;
  installerId: string;
  vehicleId: number;
}
export const loadDevices = async ({
  companyId,
}: IRequest): Promise<IOutput[]> => {
  const response = await Api.get(`/device_tracker/company/${companyId}`);
  return response.data.payload;
};

export const useLoadDevices = ({ companyId }: IRequest) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['devicesData', { companyId }],
    queryFn: () =>
      loadDevices({
        companyId,
      }),
  });
};
