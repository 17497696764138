import { Container, Paper } from '@material-ui/core';
import { useCustomTheme } from 'context/themeProvider';
import { useMobileResolution } from 'hooks/useMobileResolution';
import { useFormContainerStyle } from './style';

interface ILayout {
  children: React.ReactNode;
}

export const Layout = ({ children }: ILayout) => {
  const { appTheme: currentTheme } = useCustomTheme();
  const isMobile = useMobileResolution();
  const classes = useFormContainerStyle({ currentTheme, isMobile });
  return (
    <Container maxWidth={false} className={classes.root}>
      <Paper className={[classes.paper, classes.borderStyle].join(' ')}>
        {children}
      </Paper>
    </Container>
  );
};
