/* eslint-disable eqeqeq */
import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

interface IProps extends StandardTextFieldProps {
  control: any;
  errorMessage?: string;
  error?: boolean;
  name: string;
  type?: string;
  width?: string;
  size?: 'medium' | 'small';
  label?: string;
  placeholder?: string;
  autoCompleteInput?: boolean;
  options?: {
    value: string;
    label: string;
  }[];
  select?: boolean;
  handleChange?: (value: string) => void;
  children?: ReactNode;
}

export const ControlledInput = ({
  name,
  control,
  error,
  errorMessage,
  placeholder,
  label,
  handleChange,
  children,
  options,
  select = false,
  autoCompleteInput = false,
  size = 'small',
  type = 'text',
  width = '250px',
  ...rest
}: IProps) => {
  if (autoCompleteInput) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value } }) => (
          <Autocomplete
            id="combo-box-demo"
            disabled={rest.disabled}
            options={options || []}
            getOptionLabel={option => option.label}
            value={{
              label:
                options?.find(option => option.value == value)?.label || '',
              value,
            }}
            getOptionSelected={(option, value) => option.value === value.value}
            onInputChange={(event, newInputValue) => {
              if (newInputValue !== '' && handleChange) {
                handleChange(newInputValue);
              }
            }}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField
                {...rest}
                {...params}
                size={size}
                name={name}
                type={type}
                label={label}
                value={value}
                placeholder={placeholder}
                helperText={errorMessage}
                variant="outlined"
                error={error}
              />
            )}
          />
        )}
      />
    );
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          {...rest}
          style={{ width }}
          size={size}
          select={select}
          name={name}
          type={type}
          label={label}
          onChange={event => {
            onChange(event);
            if (handleChange) handleChange(event.target.value.toString());
          }}
          value={value}
          placeholder={placeholder}
          helperText={errorMessage}
          variant="outlined"
          error={error}
        >
          {children}
        </TextField>
      )}
    />
  );
};
