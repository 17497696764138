/* eslint-disable no-underscore-dangle */
import {
  BatteryChargingFull,
  Build,
  FlashOn,
  Map,
  PinDrop,
  Satellite,
  SignalCellularAlt,
  Speed,
  VpnKey,
} from '@material-ui/icons';
import { AddressGoogleMapsLink } from 'components/RenderCustomValues/AddressGoogleMapsLink';
import { BatteryPercent } from 'components/RenderCustomValues/BatteryPercent';
import { BatteryVoltage } from 'components/RenderCustomValues/BatteryVoltage';
import { EventLabel } from 'components/RenderCustomValues/EventLabel';
import { GsmSignalIcon } from 'components/RenderCustomValues/GsmSignal';
import { IsLocked } from 'components/RenderCustomValues/IsLocked';
import { IsOn } from 'components/RenderCustomValues/IsOn';
import { SatelliteSignal } from 'components/RenderCustomValues/SatelliteSignal/index.js';
import { SpeedKmh } from 'components/RenderCustomValues/Speed/index.js';
import { TreatmentGrid } from 'components/RenderCustomValues/Treatments';
import { DateTimeFormat } from '../../components/RenderCustomValues/DateTimeFormat/index.js';
import { LatLonFormat } from '../../components/RenderCustomValues/LatLngFormat';

export const eventGridColumns: {
  id: number;
  label: string;
  value: any;
  minWidth?: number;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  alignRow?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
  default: boolean;
  name: any;
  description?: string;
  icon?: JSX.Element;
  render?: (row: any) => JSX.Element;
}[] = [
  {
    id: 5,
    label: 'Evento',
    value: 'event',
    default: true,
    name: 'event',
    align: 'center',
    alignRow: 'left',
    render: ({
      event,
      occurrences,
      status,
    }: {
      event: string;
      occurrences: number;
      status: string;
    }) => (
      <EventLabel event={event} occurrences={occurrences} status={status} />
    ),
  },

  {
    id: 1,
    label: 'Id_Disp',
    value: 'imei',
    default: true,
    name: 'device_id',
    align: 'center',
    alignRow: 'center',
  },
  {
    id: 2,
    label: 'Fabricante',
    value: 'manufacturer',
    default: true,
    name: 'manufacturer',
    align: 'center',
    alignRow: 'center',
  },
  {
    id: 3,
    label: 'Cliente',
    value: 'name',
    default: true,
    name: 'name',
    // minWidth: 350,
    align: 'center',
    alignRow: 'left',
  },
  {
    id: 4,
    label: 'Placa',
    value: 'plate',
    default: true,
    name: 'plate',
    align: 'center',
    alignRow: 'center',
  },
  {
    id: 6,
    label: 'Data GPS',
    value: 'dateTime',
    default: true,
    name: 'date_time',
    align: 'center',
    alignRow: 'center',
    render: ({ dateTime }: { dateTime: string }) => (
      <DateTimeFormat date={dateTime} />
    ),
  },
  {
    id: 7,
    label: 'Data Sistema',
    value: 'dateSystem',
    default: true,
    name: 'date_system',
    align: 'center',
    alignRow: 'center',
    render: ({ dateSystem }: { dateSystem: string }) => (
      <DateTimeFormat date={dateSystem} />
    ),
  },
  {
    id: 8,
    label: 'Latitude',
    value: 'latitude',
    default: false,
    name: 'latitude',
    align: 'center',
    alignRow: 'center',
    render: ({ latitude }: { latitude: string }) => (
      <LatLonFormat coord={latitude} />
    ),
  },
  {
    id: 9,
    label: 'Longitude',
    value: 'longitude',
    default: false,
    name: 'longitude',
    alignRow: 'center',
    align: 'center',
    render: ({ longitude }: { longitude: string }) => (
      <LatLonFormat coord={longitude} />
    ),
  },
  {
    id: 10,
    label: 'Abrir no google maps',
    default: true,
    value: 'longitude',
    name: 'google_maps',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <Map style={{ fontSize: 15 }} />,
    render: ({
      longitude,
      latitude,
    }: {
      longitude: string;
      latitude: string;
    }) => (
      <AddressGoogleMapsLink
        lat={latitude}
        lng={longitude}
        label={<PinDrop style={{ fontSize: 15 }} />}
      />
    ),
  },
  {
    id: 11,
    label: 'Satellite',
    value: 'satellite',
    default: true,
    name: 'satellite',
    align: 'center',
    alignRow: 'center',
    icon: <Satellite fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ satellite }: { satellite: string }) => (
      <SatelliteSignal signal={satellite} />
    ),
  },
  {
    id: 12,
    label: 'GSM',
    value: 'signal',
    align: 'center',
    default: true,
    name: 'signal',
    alignRow: 'center',
    icon: <SignalCellularAlt fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({
      signal,
      manufacturer,
    }: {
      signal: string;
      manufacturer: string;
    }) => <GsmSignalIcon signal={signal} manufacturer={manufacturer} />,
  },
  {
    id: 13,
    label: 'Ignição',
    value: 'ignition',
    default: true,
    name: 'ignition',
    align: 'center',
    alignRow: 'center',
    icon: <VpnKey fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ ignition }: { ignition: boolean }) => <IsOn isOn={ignition} />,
  },
  {
    id: 14,
    label: 'Velocidade',
    value: 'speed',
    default: true,
    name: 'speed',
    align: 'center',
    alignRow: 'center',
    icon: <Speed fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ speed }: { speed: string }) => <SpeedKmh speed={speed} />,
  },

  {
    id: 17,
    label: 'Tensão',
    value: 'voltage',
    default: true,
    name: 'voltage',
    align: 'center',
    alignRow: 'center',
    icon: <FlashOn fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ voltage }: { voltage: string }) => (
      <BatteryVoltage voltage={voltage} />
    ),
  },
  {
    id: 18,
    label: 'Bateria',
    value: 'bateryPercent',
    default: true,
    name: 'batery_percent',
    align: 'center',
    alignRow: 'center',
    icon: <BatteryChargingFull fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({
      bateryPercent,
      manufacturer,
    }: {
      bateryPercent: string;
      manufacturer: string;
    }) => (
      <BatteryPercent
        batteryPercent={bateryPercent}
        manufacturer={manufacturer}
      />
    ),
  },
  {
    id: 15,
    label: 'S1',
    value: 'out1',
    default: true,
    name: 'out1',
    align: 'center',
    alignRow: 'center',
    render: ({ out1, model }: { out1: boolean; model: string }) => (
      <IsLocked isOn={out1} model={model} />
    ),
  },
  {
    id: 21,
    label: 'Tratamento',
    value: 'treatment',
    default: true,
    name: 'treatment',
    description: '',
    align: 'center',
    alignRow: 'center',
    icon: <Build fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ vehicleId }: { vehicleId: string }) => (
      <TreatmentGrid vehicleId={vehicleId} />
    ),
  },
  // {
  //   id: 16,
  //   label: 'S2',
  //   value: 'out2',
  //   default: false,
  //   name: 'out2',
  //   alignRow: 'center',
  //   align: 'center',
  //   render: ({ out2, model }: { out2: boolean; model: string }) => (
  //     <IsLocked isOn={out2} model={model} />
  //   ),
  // },
];
