import { useQuery } from 'react-query';
import Api from 'services/api';
import { IServiceOrder } from '../types';

interface IProps {
  column?: string;
  payload?: string;
}

export const loadServiceOrders = async ({
  column,
  payload,
}: IProps): Promise<IServiceOrder[]> => {
  const response = await Api.get(
    `service_order/findMany?column=${column}&payload=${payload}`,
  );
  return response.data.payload;
};

export const useLoadServiceOrders = ({ column, payload }: IProps) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['serviceOrders', column, payload],
    queryFn: () => loadServiceOrders({ column, payload }),
    enabled: false,
  });
};
