export enum ServiceOrderStatus {
  canceled = 'CANCELED',
  open = 'OPEN',
  executed = 'EXECUTED',
  frustratedVisit = 'FRUSTRATED_VISIT',
}

export enum ServiceOrderReason {
  installation = 'INSTALLATION',
  transference = 'TRANSFERENCE',
  maintenance = 'MAINTENANCE',
  removal = 'REMOVAL',
}

export enum ServiceOrderLocation {
  client = 'CLIENT',
  installer = 'INSTALLER',
  other = 'OTHER',
}

export interface IServiceOrder {
  id?: number;
  companyId: number;
  installerId?: number | string | null;
  vehicleId?: number | string | null;
  deviceId?: number | string | null;
  newDeviceId?: number | string | null;
  clientId?: number | string | null;
  reason: ServiceOrderReason;
  serviceLocation: ServiceOrderLocation;
  translatedReason?: string;
  translatedStatus?: string;
  translatedServiceLocation?: string;
  status: ServiceOrderStatus;
  observation?: string;
  installerAddress?: string;
  clientAddress?: string;
  address?: string;
  finished?: boolean;
  distance?: number;
  totalPrice?: number;
  typedDistance?: boolean;
  requestedDate?: Date | null;
  scheduledDate?: Date | null;
  installerName?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt: string;
  updatedAt: string;
  plate?: string;
  imei?: string;
}
