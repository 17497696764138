import { Form } from 'components/Form';
import { Layout } from 'components/Layout';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useLoadInstallers } from 'pages/DeviceManagement/api/loadInstallers';
import { useLoadDevices } from 'pages/DeviceManagement/api/loadDevices';
import { useLoadVehicles } from 'pages/DeviceManagement/api/loadVehicles';
import { useAuth } from 'context/authProvider';
import { useDisclosure } from 'hooks/useDisclosure';
import { Box } from '@material-ui/core';
import { AssignmentInd, DriveEta } from '@material-ui/icons';
import { ServiceOrderForm } from './components/ServiceOrderForm';
import {
  IServiceOrder,
  ServiceOrderLocation,
  ServiceOrderReason,
  ServiceOrderStatus,
} from './types';
import { useCreateServiceOrder } from './api/createServiceOrder';
import Action from './components/Actions';
import { useLoadServiceOrders } from './api/getServiceOrders';
import { useUpdateServiceOrder } from './api/updateServiceOrder';
import { ServiceOrderSearchModal } from './components/ServiceOrderSearchModal';

function requiredField(schema: yup.StringSchema | yup.NumberSchema) {
  return schema.required('Campo obrigatório');
}

const schema = yup.object().shape({
  installerId: requiredField(yup.string()),
  clientId: requiredField(yup.string()),
  address: requiredField(yup.string()),
  vehicleId: requiredField(yup.string()),
  deviceId: yup.string().optional(),
  newDeviceId: yup.string().optional(),
  reason: requiredField(yup.string()),
  requestedDate: yup
    .date()
    .typeError('A data de solicitação é inválida')
    .required('A data de solicitação é obrigatória'),
  scheduledDate: yup
    .date()
    .typeError('A data agendada é inválida')
    .required('A data de agendamento é obrigatória'),
  serviceLocation: requiredField(yup.string()),
  status: requiredField(yup.string()),
  finished: yup.boolean(),
  observation: yup.string().optional(),
});

const searchOptions = [
  { id: 2, label: 'Motivo', value: 'reason' },
  { id: 3, label: 'Status', value: 'status' },
  { id: 4, label: 'Placa', value: 'vehicleId' },
];

const headCells = [
  { id: 1, label: 'Imei', value: 'imei', minWidth: '' },
  { id: 2, label: 'Placa', value: 'plate', minWidth: '' },
  { id: 3, label: 'Motivo', value: 'translatedReason', minWidth: '' },
  { id: 4, label: 'Status', value: 'translatedStatus', minWidth: '' },
];

export const ServiceOrder = () => {
  const [searchParams, setSearchParams] = useState<{
    column: string;
    payload: string;
  }>({
    column: '',
    payload: '',
  });
  const [action, setAction] = useState<{
    mode: 'view' | 'edit';
    index: number;
  } | null>(null);
  const [selectedRow, setSelectedRow] = useState<number[]>([]);
  const {
    isOpen: isModalOpen,
    open: openModal,
    toggle: toggleModal,
  } = useDisclosure();
  const { mutateAsync, isLoading } = useCreateServiceOrder();
  const { mutateAsync: updateServiceOrder, isLoading: isUpdating } =
    useUpdateServiceOrder();
  const {
    data: serviceOrders,
    isLoading: isLoadingServiceOrders,
    refetch,
  } = useLoadServiceOrders({
    column: searchParams.column,
    payload: searchParams.payload,
  });

  const { user } = useAuth();
  const { data: installers } = useLoadInstallers({ companyId: user.companyId });
  const { data: devices } = useLoadDevices({
    companyId: user.companyId,
  });
  const { data: vehicles } = useLoadVehicles({
    companyId: user.companyId,
  });
  const [defaultValues] = useState<Partial<IServiceOrder>>({
    companyId: 0,
    deviceId: '',
    newDeviceId: '',
    installerId: '',
    clientId: '',
    vehicleId: '',
    distance: 0,
    totalPrice: 0,
    observation: '',
    address: '',
    installerAddress: '',
    clientAddress: '',
    status: ServiceOrderStatus.open,
    reason: ServiceOrderReason.installation,
    serviceLocation: ServiceOrderLocation.other,
    finished: false,
    typedDistance: false,
    requestedDate: null,
    scheduledDate: null,
  });

  const handleSelectModalRow = useCallback(row => {
    setSelectedRow([row]);
  }, []);

  const searchModalActions = useMemo(
    () => [
      {
        id: 1,
        title: 'Editar',
        icon: AssignmentInd,
        action: () => {
          setAction({
            index: selectedRow[0],
            mode: 'edit',
          });
          toggleModal();
        },
      },
      {
        id: 2,
        title: 'Visualizar',
        icon: DriveEta,
        action: () => {
          setAction({
            index: selectedRow[0],
            mode: 'view',
          });
          toggleModal();
        },
      },
    ],
    [selectedRow, toggleModal],
  );

  const handleSearch = (column: string, payload: string) => {
    setSearchParams({
      column,
      payload,
    });
    setTimeout(() => {
      refetch();
    }, 500);
  };

  return (
    <>
      <Form
        style={{ display: 'flex', width: '100%' }}
        defaultValues={defaultValues}
        onSubmit={(values: any) => {
          if (action?.mode === 'edit' && serviceOrders) {
            const data = serviceOrders.find(order => order.id === action.index);
            updateServiceOrder(
              {
                ...values,
                id: data?.id,
              },
              {
                onSuccess: () => {
                  const button = document.getElementById('clean-button');
                  button?.click();
                  refetch();
                },
              },
            ).catch((err: any) => {
              console.log('err', err.message);
            });
          } else {
            mutateAsync(values, {
              onSuccess: () => {
                const button = document.getElementById('clean-button');
                button?.click();
                refetch();
              },
              onError: () => {
                console.log('error');
              },
            }).catch((err: any) => {
              console.log('err', err.message);
            });
          }
        }}
        schema={schema}
        id="service-order-form"
      >
        {({ control, formState, setValue, watch }) => (
          <>
            <Layout>
              <ServiceOrderForm
                serviceOrders={serviceOrders || []}
                action={action}
                devices={devices || []}
                installers={installers || []}
                vehicles={vehicles?.vehicles || []}
                control={control}
                formState={formState}
                setValue={setValue}
                watch={watch}
              />
            </Layout>
            <Box style={{ padding: '10px' }}>
              <Action
                action={action}
                isSaving={isLoading || isUpdating}
                clean={() => {
                  setAction(null);
                  setValue('deviceId', '');
                  setValue('newDeviceId', '');
                  setValue('installerId', '');
                  setValue('clientId', '');
                  setValue('observation', '');
                  setValue('address', '');
                  setValue('distance', 0);
                  setValue('totalPrice', 0);
                  setValue('installerAddress', '');
                  setValue('clientAddress', '');
                  setValue('vehicleId', '');
                  setValue('status', ServiceOrderStatus.open);
                  setValue('reason', ServiceOrderReason.installation);
                  setValue('serviceLocation', ServiceOrderLocation.other);
                  setValue('finished', false);
                  setValue('typedDistance', false);
                  setValue('requestedDate', null);
                  setValue('scheduledDate', null);
                }}
                openSearchModal={() => openModal()}
              />
            </Box>
            <ServiceOrderSearchModal
              selectAllRows={() => {}}
              searchForMoreThanOne={false}
              isOpen={isModalOpen}
              toggle={() => {
                toggleModal();
              }}
              title="Ordens de serviço"
              searchOptions={searchOptions}
              tableActions={searchModalActions}
              vehicles={vehicles?.vehicles || []}
              headCells={headCells}
              onSearch={(column, payload) => {
                handleSearch(column, payload?.toString() || '');
              }}
              onRowClick={row => {
                setValue('deviceId', '');
                setValue('newDeviceId', '');
                setValue('installerId', '');
                setValue('totalPrice', 0);
                setValue('clientId', '');
                setValue('observation', '');
                setValue('clientAddress', '');
                setValue('installerAddress', '');
                setValue('address', '');
                setValue('vehicleId', '');
                setValue('status', ServiceOrderStatus.open);
                setValue('reason', ServiceOrderReason.installation);
                setValue('serviceLocation', ServiceOrderLocation.other);
                setValue('finished', false);
                setValue('distance', 0);
                setValue('typedDistance', false);
                setValue('requestedDate', null);
                setValue('scheduledDate', null);
                handleSelectModalRow(row);
              }}
              dataCells={serviceOrders || []}
              selectedRow={selectedRow}
              loading={isLoadingServiceOrders}
            />
          </>
        )}
      </Form>
    </>
  );
};
