import { Tooltip, Typography } from '@material-ui/core';

interface BatteryPercentProps {
  batteryPercent: string;
  manufacturer: string;
}

function suntechFormat(value: string) {
  const numberValue = parseFloat(value);
  if (numberValue >= 4.2) return { label: `${numberValue}V`, percent: 100 };
  if (numberValue >= 4.15) return { label: `${numberValue}V`, percent: 90 };
  if (numberValue >= 4.1) return { label: `${numberValue}V`, percent: 80 };
  if (numberValue >= 4.0) return { label: `${numberValue}V`, percent: 70 };
  if (numberValue >= 3.95) return { label: `${numberValue}V`, percent: 60 };
  if (numberValue >= 3.9) return { label: `${numberValue}V`, percent: 50 };
  if (numberValue >= 3.8) return { label: `${numberValue}V`, percent: 40 };
  if (numberValue >= 3.7) return { label: `${numberValue}V`, percent: 30 };
  if (numberValue >= 3.6) return { label: `${numberValue}V`, percent: 20 };
  if (numberValue >= 3.5) return { label: `${numberValue}V`, percent: 10 };
  if (numberValue >= 3.4) return { label: `${numberValue}V`, percent: 5 };
  return { label: '0V', percent: 0 };
}

function gt06Format(value: string) {
  const numberValue = parseFloat(value);

  if (numberValue > 6) {
    return {
      percent: numberValue,
      label: numberValue,
    };
  }

  if (numberValue === 6) {
    return {
      percent: 100,
      label: '6 - Alta',
    };
  }
  if (numberValue === 5) {
    return {
      percent: 83,
      label: '5 - Alta',
    };
  }
  if (numberValue === 4) {
    return {
      percent: 67,
      label: '4 - Media',
    };
  }
  if (numberValue === 3) {
    return {
      percent: 50,
      label: '3 - Baixa',
    };
  }
  if (numberValue === 2) {
    return {
      percent: 33,
      label: '2 - Muita baixa',
    };
  }
  if (numberValue === 1) {
    return {
      percent: 17,
      label: '',
    };
  }
  if (numberValue === 0) {
    return {
      percent: 0,
      label: '1 - Extremamente baixa',
    };
  }
  return {
    percent: 0,
    label: value,
  };
}

export function BatteryPercent({
  batteryPercent,
  manufacturer,
}: BatteryPercentProps) {
  if (batteryPercent === undefined || batteryPercent === null) return <>-</>;

  if (manufacturer?.toLowerCase() === 'bws') {
    return (
      <div>
        <Tooltip title={`${batteryPercent}%`} arrow placement="right-start">
          <Typography variant="inherit">{batteryPercent}%</Typography>
        </Tooltip>
      </div>
    );
  }

  if (
    ['gt06', 'x3tech', 'logymetria', 'hinova'].includes(
      manufacturer?.toLowerCase(),
    )
  ) {
    const value = gt06Format(batteryPercent);
    return (
      <div>
        <Tooltip title={value.label} arrow placement="right-start">
          <Typography variant="inherit">{value.percent}%</Typography>
        </Tooltip>
      </div>
    );
  }

  if (manufacturer?.toLowerCase() === 'suntech') {
    const value = suntechFormat(batteryPercent);
    return (
      <div>
        <Tooltip title={value.label} arrow placement="right-start">
          <Typography variant="inherit">{value.percent}%</Typography>
        </Tooltip>
      </div>
    );
  }
  return (
    <div>
      <Typography variant="inherit">{batteryPercent}</Typography>
    </div>
  );
}
