import { makeStyles, Theme } from '@material-ui/core';

export const useFormContainerStyle = makeStyles<
  Theme,
  { currentTheme: string; isMobile: boolean }
>(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    alignItems: 'baseline',
    height: ({ isMobile }) => (isMobile ? '100%' : '100vh'),
    // paddingTop: 100,
    // paddingBottom: 24,
  },
  borderStyle: {
    borderColor: ({ currentTheme }) =>
      currentTheme === 'dark' ? '#666666' : '#D0D0D0',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    border: '1px solid',
  },
  paper: {
    overflowY: 'scroll',
    background: theme.palette.background.paper,
    borderRadius: 0,
    padding: '30px 26px 26px',
    flexGrow: 1,
    height: ({ isMobile }) => (isMobile ? '100%' : '100vh'),
  },
}));
