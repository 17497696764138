/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
import msk from 'msk';
import React from 'react';

export const _masks = {
  plate: (value: string) => msk.fit(value.toUpperCase(), 'AAA-9S99'),
  vin: (value: string) => msk.fit(value.toUpperCase(), 'SSS SSSSSS SS SSSSSS'),
};

export const masks = {
  login: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = e.target.value.replace(' ', '')),
  password: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value = e.target.value.replace(' ', '');
    if (e.target.value.length > 32)
      e.target.value = e.target.value.slice(0, 32);
  },
  cep: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, '99999-999')),
  cpf: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, '999.999.999-99')),
  cnpj: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, '99.999.999/9999-99')),
  cpfcnpj: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.length < 15) {
      e.target.value = msk.fit(e.target.value, '999.999.999-99');
    } else {
      e.target.value = msk.fit(e.target.value, '99.999.999/9999-99');
    }
  },
  addressNumber: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => (e.target.value = msk.fit(e.target.value, '99999999')),
  rg: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, '99.999.999-9')),
  phone: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    if (e.target.value.length <= 10) {
      e.target.value = msk.fit(e.target.value, '(99) 9999-9999');
    } else {
      e.target.value = msk.fit(e.target.value, '(99) 9 9999-9999');
    }
  },
  cellphone: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    e.target.value = msk.fit(e.target.value, '(99) 9 9999-9999');
  },
  renavam: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value = msk.fit(e.target.value, '99999999999');
  },
  vin: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, 'SSS SSSSSS SS SSSSSS')),
  vinSearch: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (/^\d+$/.test(e.target.value)) {
      e.target.value = msk.fit(e.target.value, '999999');
    } else {
      e.target.value = msk.fit(e.target.value, 'SSS SSSSSS SS SSSSSS');
    }
  },
  year: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, '9999')),
  plate: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value.toUpperCase(), 'AAA-9S99')),

  plateSearch: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (/^\d+$/.test(e.target.value[0])) {
      e.target.value = msk.fit(e.target.value.toUpperCase(), '9S99');
    } else {
      e.target.value = msk.fit(e.target.value.toUpperCase(), 'AAA-9S99');
    }
  },
  plateSearchMany: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.target.value.length > 8) {
      const plates = e.target.value.split(/[,\s]+/).map(plate => {
        if (/^\d+$/.test(plate[0])) {
          return msk.fit(plate.toUpperCase(), '9S99');
        }
        return msk.fit(plate.toUpperCase(), 'AAA-9S99');
      });
      e.target.value = plates.join(' ');
    } else if (/^\d+$/.test(e.target.value[0])) {
      e.target.value = msk.fit(e.target.value.toUpperCase(), '9S99');
    } else {
      e.target.value = msk.fit(e.target.value.toUpperCase(), 'AAA-9S99');
    }
  },
  uf: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = e.target.value.substring(0, 2).toUpperCase()),
  text: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = e.target.value.replace(/[^A-Za-z]/, '')),
  number: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = e.target.value.replace(/\D/, '')),
  imei: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, '99999999999999999999')),
  imeilot: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const lines = value.split('\n');

    if (lines.length > 1) {
      lines[lines.length - 1] = msk.fit(
        lines[lines.length - 1],
        '99999999999999999999,\n9',
      );
      e.target.value = lines.join('\n');
    } else {
      e.target.value = msk.fit(e.target.value, '99999999999999999999,\n9');
    }
  },
  imeilist: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const lines = value.split('\n');

    if (lines.length > 1) {
      lines[lines.length - 1] = msk.fit(
        lines[lines.length - 1],
        '999999999999999\n',
      );
      e.target.value = lines.join('\n');
    } else {
      e.target.value = msk.fit(e.target.value, '999999999999999\n');
    }
  },
  iccid: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    (e.target.value = msk.fit(e.target.value, '99999999999999999999')),
  iccidAndLineNumber: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    // only numbers and spaces and commas
    e.target.value = e.target.value.replace(/[^0-9\s,]/g, '');
  },
  currency: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;

    const price = value.replace(/\D/g, '').split('');

    if (price.length > 2) price.splice(-2, 0, ',');
    if (price.length > 6) price.splice(-6, 0, '.');
    if (price.length > 10) price.splice(-10, 0, '.');
    e.currentTarget.value = price.join('');
  },
  email: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value = e.target.value.toLowerCase().replace(/\s/g, '');
  },
  birth: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    e.target.value = msk.fit(e.target.value, '99/99/9999');
  },
};
