import { useQuery } from 'react-query';
import Api from 'services/api';
import { IVehicles } from '../types';

interface IRequest {
  companyId: number;
}

interface IOutput {
  id: number;
  vehicles: IVehicles[];
}
export const loadVehicles = async ({
  companyId,
}: IRequest): Promise<IOutput> => {
  const response = await Api.get(`/vehicle/company/${companyId}`);
  return response.data.payload;
};

export const useLoadVehicles = ({ companyId }: IRequest) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['vehiclesData', { companyId }],
    queryFn: () =>
      loadVehicles({
        companyId,
      }),
  });
};
