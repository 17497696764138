import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { useCustomTheme } from 'context/themeProvider';
import { useCompanyStyle } from 'pages/Company/companyStyle';

type Props = {
  openSearchModal: () => void;
  clean: () => void;
  isSaving?: boolean;
  action: {
    mode: 'view' | 'edit';
    index: number;
  } | null;
};
export default function Action({
  openSearchModal,
  clean,
  action,
  isSaving = false,
}: Props) {
  const { appTheme: currentTheme } = useCustomTheme();
  const classes = useCompanyStyle({ currentTheme });

  return (
    <section
      className={[classes.borderStyle, classes.floatActionSection].join(' ')}
    >
      <Button
        type="submit"
        disabled={isSaving || action?.mode === 'view'}
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        startIcon={<SaveIcon />}
      >
        {isSaving ? 'Carregando...' : 'Salvar'}
      </Button>
      <Button
        type="button"
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        startIcon={<SearchIcon />}
        onClick={openSearchModal}
      >
        Procurar
      </Button>
      <Button
        id="clean-button"
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        onClick={() => clean()}
        startIcon={<CancelIcon />}
      >
        Limpar
      </Button>
    </section>
  );
}
