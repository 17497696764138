import { yupResolver } from '@hookform/resolvers/yup';
import {
  FieldValues,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { AnyObjectSchema, ObjectSchema } from 'yup';

type FormProps<TFormValues extends FieldValues, Schema> = {
  style?: any;
  onSubmit: SubmitHandler<TFormValues>;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  defaultValues?: any;
  id?: string;
  schema: Schema;
};

export const Form = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends AnyObjectSchema = ObjectSchema<any, any, any, any>,
>({
  children,
  onSubmit,
  id,
  options,
  schema,
  defaultValues,
  style,
}: FormProps<TFormValues, Schema>) => {
  const methods = useForm<TFormValues>({
    ...options,
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <form style={style} onSubmit={methods.handleSubmit(onSubmit)} id={id}>
      {children(methods)}
    </form>
  );
};
