import { useMutation } from 'react-query';

import api from 'services/api';
import { useToast } from 'context/toastProvider';
import { queryClient } from 'context';
import {
  IServiceOrder,
  ServiceOrderLocation,
  ServiceOrderReason,
  ServiceOrderStatus,
} from '../types';

interface ICreateServiceOrder {
  installerId?: number;
  vehicleId?: number;
  deviceId?: number;
  clientId?: number;
  reason: ServiceOrderReason;
  serviceLocation: ServiceOrderLocation;
  status: ServiceOrderStatus;
  observation?: string;
  address?: string;
  finished?: boolean;
  requestedDate?: Date | null;
  scheduledDate?: Date | null;
}

export async function createServiceOrder(
  data: ICreateServiceOrder,
): Promise<IServiceOrder> {
  const response = await api.post('service_order/', data);
  return response.data.payload;
}

export const useCreateServiceOrder = () => {
  const { addToast } = useToast();

  return useMutation({
    onMutate: () => {
      queryClient.cancelQueries('serviceOrders');
      queryClient.cancelQueries('devicesData');
      queryClient.cancelQueries('vehiclesData');
    },
    onSuccess: () => {
      addToast('success', 'Ordem de serviço criada');
      queryClient.invalidateQueries('serviceOrders');
      queryClient.invalidateQueries('devicesData');
      queryClient.invalidateQueries('vehiclesData');
    },
    onError: (error: any) => {
      return addToast('error', error.response.data.message);
    },
    mutationFn: createServiceOrder,
  });
};
