export const serviceOrderReasons = [
  {
    value: 'INSTALLATION',
    label: 'Instalação',
  },
  {
    value: 'MAINTENANCE',
    label: 'Manutenção',
  },
  {
    value: 'REMOVAL',
    label: 'Remoção',
  },
];

export const serviceOrderStatus = [
  {
    value: 'CANCELED',
    label: 'Cancelado',
  },
  {
    value: 'OPEN',
    label: 'Aberto',
  },
  {
    value: 'EXECUTED',
    label: 'Executado',
  },
  {
    value: 'FRUSTRATED_VISIT',
    label: 'Visita frustada',
  },
];

export const serviceOrderLocations = [
  {
    value: 'CLIENT',
    label: 'Cliente',
  },
  {
    value: 'INSTALLER',
    label: 'Instalador',
  },
  {
    value: 'OTHER',
    label: 'Outro',
  },
];
