import {
  Backdrop,
  IconButton,
  makeStyles,
  Modal,
  ModalProps,
  Theme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { DetailedHTMLProps, HTMLAttributes, useEffect } from 'react';

type props = {
  isOpen: boolean;
  handleClose(): void;
  children: JSX.Element;
  modalProps?: React.ComponentType<ModalProps>;
  paperProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  clickAwayToClose?: boolean;
  width?: string | number;
  onClickAway?: () => void;
  hasMinHeight?: boolean;
};

type styleProps = {
  width: string | number;
  hasMinHeight: boolean;
};

const useStyles = makeStyles<Theme, styleProps>(({ palette, ...theme }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: props => props.width,
    margin: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  paper: {
    maxHeight: '90vh',
    overflowY: 'scroll',
    // minHeight: ({ hasMinHeight }) => (hasMinHeight ? 614 : 'auto'),
    background: palette.background.default,
    borderRadius: 30,
    backgroundColor: palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
  },
}));

export function CustomModal({
  isOpen = false,
  handleClose,
  children,
  modalProps,
  paperProps,
  width = '70%',
  hasMinHeight = true,
}: props): JSX.Element {
  const classes = useStyles({ width, hasMinHeight });

  useEffect(() => {
    function closeBasedOnKey(e: KeyboardEvent) {
      if (e.key === 'Escape' && isOpen) {
        handleClose();
      }
    }
    document.addEventListener('keyup', closeBasedOnKey);

    return () => document.removeEventListener('keyup', closeBasedOnKey);
  }, [handleClose, isOpen]);

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...modalProps}
    >
      <div className={classes.paper} {...paperProps}>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <Close />
        </IconButton>
        {children}
      </div>
    </Modal>
  );
}
