/* eslint-disable eqeqeq */
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ControlledInput } from 'components/ControlledInput';
import { Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import DatePicker from 'components/DatePicker/index.js';
import { ReactNode, useEffect, useState } from 'react';
import { IVehicles } from 'pages/DeviceManagement/types';
import { Help } from '@material-ui/icons';
import { serviceOrderLocations, serviceOrderStatus } from '../utils/optionts';
import { IServiceOrder } from '../types';

interface IProps {
  control: any;
  formState: any;
  setValue: UseFormSetValue<Record<string, unknown>>;
  watch: UseFormWatch<any>;
  serviceOrders: IServiceOrder[];
  action: {
    mode: 'view' | 'edit';
    index: number;
  } | null;
  installers: {
    id: number;
    contactName: string;
    corporateName: string;
    street: string;
    installationCost: string;
    uninstallationCost: string;
    unsuccessfulVisitCost: string;
    kmCoverage: string;
    kmTraveledCost: string;
    maintenanceCost: string;
    transferCost: string;
  }[];
  devices: {
    id: number;
    imei: string;
    installerId: string;
    vehicleId: number;
  }[];
  vehicles: IVehicles[];
}

export const ServiceOrderForm = ({
  control,
  formState,
  setValue,
  serviceOrders,
  devices,
  installers,
  vehicles,
  action,
  watch,
}: IProps) => {
  const [openDetail, setOpenDetail] = useState(false);
  const serviceOrder = serviceOrders.find(order => order.id === action?.index);
  const isFinished = serviceOrder ? serviceOrder.finished : false;
  const createdBy = serviceOrder ? serviceOrder.createdBy : '';
  const updatedBy = serviceOrder ? serviceOrder.updatedBy : '';
  const reason = watch('reason');
  const installer = watch('installerId');
  const vehicle = watch('vehicleId');
  const client = watch('clientId');
  const distance = watch('distance');
  const serviceLocation = watch('serviceLocation');
  const totalPrice = watch('totalPrice');

  const installerData = installers?.find(data => data.id === installer);

  const servicePrice: any = {
    INSTALLATION: installerData?.installationCost,
    MAINTENANCE: installerData?.maintenanceCost,
    REMOVAL: installerData?.uninstallationCost,
  };

  const filterDevices = (
    data: {
      id: number;
      imei: string;
      installerId: string;
      vehicleId: number;
    }[],
  ): ReactNode => {
    if (isFinished) {
      return data?.map(device => (
        <ListItem value={device.id} key={device.id}>
          {device.imei}
        </ListItem>
      ));
    }
    if (reason === 'INSTALLATION') {
      return data
        ?.filter(info => info.installerId === installer)
        .map(device => (
          <ListItem value={device.id} key={device.id}>
            {device.imei}
          </ListItem>
        ));
    }
    const device = data.find(device => device.vehicleId === vehicle);
    setValue('deviceId', device?.id);
    return <ListItem value={device?.id}>{device?.imei}</ListItem>;
  };

  const translatedReason: any = {
    INSTALLATION: 'Instalação',
    MAINTENANCE: 'Manutenção',
    REMOVAL: 'Remoção',
  };

  const [serviceOrderReasons, setServiceOrderReasons] = useState([
    {
      value: 'INSTALLATION',
      label: 'Instalação',
    },
    {
      value: 'MAINTENANCE',
      label: 'Manutenção',
    },
    {
      value: 'REMOVAL',
      label: 'Remoção',
    },
  ]);

  useEffect(() => {
    if (action) {
      const data = serviceOrders.find(order => order.id === action.index);
      setValue('deviceId', data?.deviceId || '');
      setValue('newDeviceId', data?.newDeviceId || '');
      setValue('installerId', data?.installerId);
      setValue('clientId', data?.clientId);
      setValue('observation', data?.observation);
      setValue('address', data?.address);
      setValue('distance', data?.distance);
      setValue('totalPrice', data?.totalPrice);
      setValue('typedDistance', data?.typedDistance);
      setValue('installerAddress', data?.installerAddress);
      setValue('clientAddress', data?.clientAddress);
      setValue('status', data?.status);
      setValue('vehicleId', data?.vehicleId);
      setValue('reason', data?.reason);
      setValue('serviceLocation', data?.serviceLocation);
      setValue('finished', data?.finished);
      setValue('requestedDate', data?.requestedDate);
      setValue('scheduledDate', data?.scheduledDate);
      setValue('finished', data?.finished);
    }
  }, [action, serviceOrders, setValue]);

  return (
    <Box
      style={{
        display: 'flex',
        gap: '20px',
        padding: '40px',
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Paper style={{ height: '100%', padding: '15px' }}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <ControlledInput
              autoCompleteInput
              options={
                vehicles?.map(vehicle => ({
                  label: vehicle?.plate,
                  value: vehicle?.id?.toString(),
                })) || []
              }
              handleChange={(value: string) => {
                if (vehicles) {
                  const vehicleData = vehicles?.find(
                    data => data.plate == value,
                  );
                  if (vehicleData) {
                    setValue('vehicleId', vehicleData.id);
                    setValue('clientId', vehicleData.clientId || '');
                    setValue(
                      'clientAddress',
                      vehicleData?.client?.street || '',
                    );
                    if (vehicleData?.device) {
                      setServiceOrderReasons([
                        {
                          value: 'MAINTENANCE',
                          label: 'Manutenção',
                        },
                        {
                          value: 'REMOVAL',
                          label: 'Remoção',
                        },
                      ]);
                    } else {
                      setServiceOrderReasons([
                        {
                          value: 'INSTALLATION',
                          label: 'Instalação',
                        },
                      ]);
                    }
                  }
                }
              }}
              control={control}
              disabled={action?.mode === 'view' || isFinished}
              name="vehicleId"
              error={!!formState.errors.vehicleId}
              errorMessage={formState.errors.vehicleId?.message}
              label="Veículo"
            />

            <TextField
              disabled
              label="Cliente"
              variant="outlined"
              size="small"
              style={{ width: '250px' }}
              value={
                vehicles?.find(data => data.id == vehicle)?.client?.name || ''
              }
            />

            <ControlledInput
              control={control}
              disabled
              name="clientAddress"
              error={!!formState.errors.clientAddress}
              errorMessage={formState.errors.clientAddress?.message}
              label="Endereço"
            />
          </Box>
        </Paper>
        <Paper style={{ height: '100%', padding: '15px' }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                width: '100%',
              }}
            >
              <ControlledInput
                handleChange={(value: string) => {
                  if (installers) {
                    const installerData = installers?.find(
                      data => data.id.toString() == value,
                    );
                    setValue('installerAddress', installerData?.street || '');
                    let reasonPrice = 0;
                    let distancePrice = 0;
                    if (reason) {
                      if (reason === 'INSTALLATION') {
                        reasonPrice = parseFloat(
                          installerData?.installationCost || '0',
                        );
                      } else if (reason === 'MAINTENANCE') {
                        reasonPrice = parseFloat(
                          installerData?.maintenanceCost || '0',
                        );
                      } else if (reason === 'REMOVAL') {
                        reasonPrice = parseFloat(
                          installerData?.uninstallationCost || '0',
                        );
                      }
                    }
                    if (distance) {
                      const KmCoverage = parseFloat(
                        installerData?.kmCoverage || '0',
                      );
                      const KmTraveledCost = parseFloat(
                        installerData?.kmTraveledCost || '0',
                      );

                      if (distance > KmCoverage) {
                        distancePrice =
                          (distance - KmCoverage) * KmTraveledCost;
                      }
                    }
                    setValue('totalPrice', reasonPrice + distancePrice);
                  }
                }}
                control={control}
                disabled={action?.mode === 'view' || isFinished}
                select
                name="installerId"
                error={!!formState.errors.installerId}
                errorMessage={formState.errors.installerId?.message}
                label="Instalador"
              >
                {installers?.map(installer => (
                  <ListItem value={installer.id} key={installer.id}>
                    {installer.corporateName}
                  </ListItem>
                ))}
              </ControlledInput>
              <ControlledInput
                control={control}
                disabled
                name="installerAddress"
                error={!!formState.errors.installerAddress}
                errorMessage={formState.errors.installerAddress?.message}
                label="Endereço"
              />
            </Box>
            <Box style={{ display: 'flex', gap: '20px' }}>
              <Typography>
                Instalação: R$ {installerData?.installationCost}
              </Typography>
              <Typography>
                Manutenção: R$ {installerData?.maintenanceCost}
              </Typography>
              <Typography>
                Retirada: R$ {installerData?.uninstallationCost}
              </Typography>
              <Typography>
                Transferência: R$ {installerData?.transferCost}
              </Typography>
              <Typography>
                Visita Frustada: R$ {installerData?.unsuccessfulVisitCost}
              </Typography>
              <Typography>
                KM Rodado: R$ {installerData?.kmTraveledCost}
              </Typography>
              <Typography>
                Abrangência: R$ {installerData?.kmCoverage}
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Paper
          style={{
            height: '100%',
            padding: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <Box style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <DatePicker
              error={formState.errors.requestedDate?.message}
              label="Data de solicitação"
              handleChangeDate={() => {}}
              name="requestedDate"
              control={control}
              disabled={action?.mode === 'view' || isFinished}
              maxDate={new Date()}
              rules={undefined}
              style={{
                width: '200px',
                marginTop: '10px',
              }}
            />
            <DatePicker
              error={formState.errors.scheduledDate?.message}
              label="Data de agendamento"
              handleChangeDate={() => {}}
              name="scheduledDate"
              control={control}
              disabled={action?.mode === 'view' || isFinished}
              rules={undefined}
              style={{
                width: '200px',
                marginTop: '10px',
              }}
            />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <ControlledInput
              control={control}
              handleChange={(value: string) => {
                if (value) {
                  let reasonPrice = 0;
                  let distancePrice = 0;
                  if (value === 'INSTALLATION') {
                    reasonPrice = parseFloat(
                      installerData?.installationCost || '0',
                    );
                  } else if (value === 'MAINTENANCE') {
                    reasonPrice = parseFloat(
                      installerData?.maintenanceCost || '0',
                    );
                  } else if (value === 'REMOVAL') {
                    reasonPrice = parseFloat(
                      installerData?.uninstallationCost || '0',
                    );
                  }

                  if (distance) {
                    const KmCoverage = parseFloat(
                      installerData?.kmCoverage || '0',
                    );
                    const KmTraveledCost = parseFloat(
                      installerData?.kmTraveledCost || '0',
                    );

                    if (distance > KmCoverage) {
                      distancePrice = (distance - KmCoverage) * KmTraveledCost;
                    }
                  }
                  setValue('totalPrice', reasonPrice + distancePrice);
                }
              }}
              disabled={action?.mode === 'view' || isFinished || !vehicle}
              select
              name="reason"
              error={!!formState.errors.reason}
              errorMessage={formState.errors.reason?.message}
              label="Motivo"
            >
              {serviceOrderReasons.map(reason => (
                <ListItem value={reason.value} key={reason.value}>
                  {reason.label}
                </ListItem>
              ))}
            </ControlledInput>
            <ControlledInput
              control={control}
              disabled={
                action?.mode === 'view' ||
                isFinished ||
                !installer ||
                reason !== 'INSTALLATION'
              }
              select
              name="deviceId"
              error={!!formState.errors.deviceId}
              errorMessage={formState.errors.deviceId?.message}
              label="Id Rastreador"
            >
              {filterDevices(devices)}
            </ControlledInput>
            {(reason === 'MAINTENANCE' || reason === 'TRANSFERENCE') && (
              <ControlledInput
                control={control}
                disabled={action?.mode === 'view' || isFinished || !installer}
                select
                name="newDeviceId"
                error={!!formState.errors.newDeviceId}
                errorMessage={formState.errors.newDeviceId?.message}
                label="Novo rastreador"
              >
                {devices
                  ?.filter(info => info.installerId === installer)
                  .map(device => (
                    <ListItem value={device.id} key={device.id}>
                      {device.imei}
                    </ListItem>
                  ))}
              </ControlledInput>
            )}
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <ControlledInput
              control={control}
              disabled={action?.mode === 'view' || isFinished}
              select
              name="status"
              error={!!formState.errors.status}
              errorMessage={formState.errors.status?.message}
              label="Status"
            >
              {serviceOrderStatus.map(data => (
                <ListItem value={data.value} key={data.value}>
                  {data.label}
                </ListItem>
              ))}
            </ControlledInput>

            <ControlledInput
              handleChange={(value: string) => {
                if (value === 'CLIENT') {
                  if (vehicles) {
                    const userData = vehicles?.find(
                      data => data.clientId === client,
                    )?.client;
                    setValue('address', userData?.street || '');
                  }
                } else if (installers) {
                  const installerData = installers?.find(
                    data => data.id === installer,
                  );
                  setValue('address', installerData?.street || '');
                }
              }}
              control={control}
              disabled={
                action?.mode === 'view' || isFinished || !installer || !client
              }
              select
              name="serviceLocation"
              error={!!formState.errors.serviceLocation}
              errorMessage={formState.errors.serviceLocation?.message}
              label="Local de atendimento"
            >
              {serviceOrderLocations.map(location => (
                <ListItem value={location.value} key={location.value}>
                  {location.label}
                </ListItem>
              ))}
            </ControlledInput>
            <ControlledInput
              control={control}
              disabled={
                action?.mode === 'view' ||
                isFinished ||
                serviceLocation !== 'OTHER'
              }
              name="address"
              error={!!formState.errors.address}
              errorMessage={formState.errors.address?.message}
              label="Endereço"
            />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <ControlledInput
              type="number"
              handleChange={(value: string) => {
                if (value) {
                  let reasonPrice = 0;
                  let distancePrice = 0;
                  if (reason) {
                    if (reason === 'INSTALLATION') {
                      reasonPrice = parseFloat(
                        installerData?.installationCost || '0',
                      );
                    } else if (reason === 'MAINTENANCE') {
                      reasonPrice = parseFloat(
                        installerData?.maintenanceCost || '0',
                      );
                    } else if (reason === 'REMOVAL') {
                      reasonPrice = parseFloat(
                        installerData?.uninstallationCost || '0',
                      );
                    }
                  }
                  const KmCoverage = parseFloat(
                    installerData?.kmCoverage || '0',
                  );
                  const KmTraveledCost = parseFloat(
                    installerData?.kmTraveledCost || '0',
                  );

                  if (parseFloat(value) > KmCoverage) {
                    distancePrice =
                      (parseFloat(value) - KmCoverage) * KmTraveledCost;
                  }
                  setValue('totalPrice', reasonPrice + distancePrice);
                }
              }}
              control={control}
              disabled={action?.mode === 'view' || isFinished}
              name="distance"
              error={!!formState.errors.serviceLocation}
              errorMessage={formState.errors.serviceLocation?.message}
              label="Distância"
            />
            {/*
            <Controller
              name="typedDistance"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={action?.mode === 'view' || isFinished}
                      checked={!!value}
                      onChange={onChange}
                      name="typedDistance"
                      color="primary"
                    />
                  }
                  label="Digitar distância"
                />
              )}
            />
  */}
          </Box>

          <Controller
            name="observation"
            control={control}
            render={({ field: { onChange, value } }) => (
              <textarea
                style={{
                  maxWidth: '500px',
                  maxHeight: '350px',
                  background: 'transparent',
                  color: 'inherit',
                  padding: '10px',
                }}
                disabled={action?.mode === 'view' || isFinished}
                onChange={onChange}
                value={value}
              />
            )}
          />

          <Controller
            name="finished"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                style={{ width: '250px' }}
                control={
                  <Checkbox
                    disabled={action?.mode === 'view' || isFinished}
                    checked={!!value}
                    onChange={onChange}
                    name="finished"
                    color="primary"
                  />
                }
                label="Finalizado"
              />
            )}
          />
        </Paper>
      </Box>
      <Paper
        style={{
          height: '100%',
          padding: '15px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '5px',
        }}
      >
        <Typography style={{ fontWeight: 'bold' }}>
          Informações da ordem de serviço
        </Typography>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          <Typography>
            Total:{' '}
            {totalPrice?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Typography>
          <Tooltip title="Detalhes">
            <IconButton onClick={() => setOpenDetail(prev => !prev)}>
              <Help />
            </IconButton>
          </Tooltip>
          {openDetail && (
            <Paper
              style={{
                position: 'absolute',
                top: '40px',
                right: 0,
                padding: '15px',
                width: '350px',
              }}
            >
              <Typography>
                Preço do serviço{' '}
                {(parseFloat(servicePrice[reason]) || 0).toLocaleString(
                  'pt-BR',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  },
                )}
              </Typography>
              <Typography>
                Distância extra:{' '}
                {parseFloat(distance) -
                  parseFloat(installerData?.kmCoverage || '0') >
                0
                  ? parseFloat(distance) -
                    parseFloat(installerData?.kmCoverage || '0')
                  : 0}
              </Typography>
              <Typography>
                Total:
                {(parseFloat(totalPrice) || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Typography>
            </Paper>
          )}
        </Box>
        <Typography>
          Placa: {vehicles?.find(data => data.id === vehicle)?.plate || ''}
        </Typography>
        <Typography>Motivo: {translatedReason[reason] || ''}</Typography>
        <Typography>Criado por: {createdBy || ''}</Typography>
        <Typography>
          Última atualização: {updatedBy || createdBy || ''}
        </Typography>
      </Paper>
    </Box>
  );
};
