import { useQuery } from 'react-query';
import Api from 'services/api';

interface IRequest {
  companyId: number;
}

interface IOutput {
  id: number;
  contactName: string;
  corporateName: string;
  street: string;
  installationCost: string;
  transferCost: string;
  uninstallationCost: string;
  unsuccessfulVisitCost: string;
  kmCoverage: string;
  kmTraveledCost: string;
  maintenanceCost: string;
  status: string;
}
export const loadInstallers = async ({
  companyId,
}: IRequest): Promise<IOutput[]> => {
  const response = await Api.get(`/installer/company/${companyId}`);
  return response.data.payload;
};

export const useLoadInstallers = ({ companyId }: IRequest) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['installersData', { companyId }],
    queryFn: () =>
      loadInstallers({
        companyId,
      }),
  });
};
