import { useMutation } from 'react-query';

import api from 'services/api';
import { useToast } from 'context/toastProvider';
import { queryClient } from 'context';
import {
  ServiceOrderLocation,
  ServiceOrderReason,
  ServiceOrderStatus,
} from '../types';

interface IUpdateServiceOrder {
  id: number;
  installerId?: number;
  vehicleId?: number;
  deviceId?: number;
  clientId?: number;
  reason?: ServiceOrderReason;
  serviceLocation?: ServiceOrderLocation;
  status?: ServiceOrderStatus;
  observation?: string;
  address?: string;
  finished?: boolean;
  requestedDate?: Date | null;
  scheduledDate?: Date | null;
}

export async function updateServiceOrder(
  data: IUpdateServiceOrder,
): Promise<void> {
  await api.put(`service_order/${data.id}`, data);
}

export const useUpdateServiceOrder = () => {
  const { addToast } = useToast();

  return useMutation({
    onMutate: () => {
      queryClient.cancelQueries('serviceOrders');
      queryClient.cancelQueries('devicesData');
      queryClient.cancelQueries('vehiclesData');
    },
    onSuccess: () => {
      addToast('success', 'Ordem de serviço atualizada');
      queryClient.invalidateQueries('serviceOrders');
      queryClient.invalidateQueries('devicesData');
      queryClient.invalidateQueries('vehiclesData');
    },
    onError: (error: any) => {
      addToast('error', error.response.data.message);
    },
    mutationFn: updateServiceOrder,
  });
};
